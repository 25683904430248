<template>
  <div class="loader">
    <span class="stroke"></span>
    <span class="stroke"></span>
    <span class="stroke"></span>
    <span class="stroke"></span>
  </div>
</template>

<script>
export default {
  name: 'StopSoundWave',
}
</script>

<style scoped>
.loader::v-deep {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 126px;
}
.loader .stroke::v-deep {
  display: block;
  position: relative;
  background: #f8c784;
  height: 50%;
  width: 10px;
  border-radius: 50px;
  margin: 0 5px;
  animation: animate 1s linear infinite;
}
</style>
