<template>
  <button type="button" class="btn btn-primary border-0 submit-button px-4 d-flex align-items-center justify-content-center" 
    :style="{'background-color': isRecord ? '#008E88!important': '#FC7A1E'}">
    <span class="material-icons align-self-center" v-if="icon">
      {{ icon }}   
    </span>
    <span class="d-flex justify-content-center">
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    icon: String,
    text: String,
    isRecord: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>
  .submit-button::v-deep{
    height:70px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 135%;
    border-radius: 20px;
  }

  .submit-button .material-icons::v-deep{
    font-size: 38px;
  }
</style>