<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">Speech Recognition</div>
        <div class="description">
          Our Thai speech recognition software is an advanced technology that allows a program to fully recognize, understand and interpret Thai spoken input such as words or phrases and accurately convert them into precise text. NLP technology plays an essential role in speech recognition by simplifying the processes to minimize the amount of time it takes to accomplish.
        </div>
      </div>
    </template>
    <template slot="input-content-center">
      <SoundWave v-if="is_speak"/>
      <StopSoundWave v-if="!is_speak"/>
      <div class="d-grid d-md-flex" v-if="!recognizing">
        <RecordButton 
          class="demo-bg-danger w-100" icon="mic" text="Speak" :isRecord="false"
          @click.native="clickSpeak('start')"/>
      </div>
      <div class="d-grid d-md-flex" v-if="recognizing">
        <RecordButton 
          class="demo-bg-danger w-100" icon="stop" text="Stop" :isRecord="true"
          @click.native="clickSpeak('stop')"/>
      </div>
    </template>
    <template slot="output-content">
      <BaseResult style="height: 653px;">
        <template slot="result-title">
          Result
        </template>
        <template slot="result-detail">
          {{ text_result }}
        </template>
      </BaseResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import SoundWave from '@/components/loader/SoundWave.vue';
import BaseResult from '@/components/result/BaseResult.vue';
import RecordButton from '@/components/button/RecordButton.vue'
import StopSoundWave from '@/components/loader/StopSoundWave.vue'

export default{
    components:{
        BaseTemplate, SoundWave, BaseResult, RecordButton, StopSoundWave
    },
    name: 'SprHome',
    data() {
    return {
      recognizing: false,
      final_transcript: "",
      recognition: null,
      text_result: "",
      is_speak: false,
    }
  },
  methods:{
    resetValue(){
      this.recognizing = false
      this.final_transcript = ""
      this.recognition = null
      this.text_result = ""
    },
    clickSpeak(state){
      if(state === 'start'){
        this.resetValue()
        this.initRecognite()
      }else{
        // this.isRecord = false;
        this.recognition.stop();
      }
    },
    initRecognite() {
      const self = this;
      self.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
      if (!self.recognition) {
        //if don't have a webkitSpeechRecognition
      } else {
          console.log('intializing')
          console.log(self.recognition)
          console.log(window)
          
          self.recognition.lang = "th-TH";
          self.recognition.continuous = true;
          self.recognition.interimResults = true;

          self.recognition.start();

          self.recognition.onstart = function() {
            self.recognizing = true;
            console.log('start')
            // start_img.src = 'mic-animate.gif';
          };

          self.recognition.onend = function () {
            self.recognizing = false;
            self.text_result = self.final_transcript
            console.log('stop')
          };

          self.recognition.onerror = function (event) {
            event
            // if (event.error == 'no-speech') {
            //   start_img.src = 'https://www.google.com/intl/en/chrome/assets/common/images/content/mic.gif';
            //   showInfo('info_no_speech');
            //   ignore_onend = true;
            // }
            // if (event.error == 'audio-capture') {
            //   start_img.src = 'https://www.google.com/intl/en/chrome/assets/common/images/content/mic.gif';
            //   showInfo('info_no_microphone');
            //   ignore_onend = true;
            // }
            // if (event.error == 'not-allowed') {
            //   if (event.timeStamp - start_timestamp < 100) {
            //     showInfo('info_blocked');
            //   } else {
            //     showInfo('info_denied');
            //   }
            //   ignore_onend = true;
            // }
          };

          self.recognition.onresult = function(event) {
            self.is_speak = true
            let interim_transcript = '';
            if (typeof (event.results) == 'undefined') {
              self.recognition.onend = null;
              self.recognition.stop();
              return;
            }
            for (var i = event.resultIndex; i < event.results.length; ++i) {
              if (event.results[i].isFinal) {
                self.final_transcript += event.results[i][0].transcript;
              } else {
                interim_transcript += event.results[i][0].transcript;
              }
            }
            self.final_transcript = self.capitalize(self.final_transcript);
            self.text_result = self.final_transcript + self.linebreak(interim_transcript);
            
            if(interim_transcript === ""){
              self.text_result = self.final_transcript 
              self.is_speak = false
            }
            console.log(self.final_transcript)
            console.log(interim_transcript)
            // final_span.innerHTML = linebreak(final_transcript);
            // interim_span.innerHTML = linebreak(interim_transcript);
            // if (final_transcript || interim_transcript) {
            //   showButtons('inline-block');
            // }
          }
      }

    },
    linebreak(s) {
      let two_line = /\n\n/g;
      let one_line = /\n/g;
      return s.replace(two_line, '<p></p>').replace(one_line, '<br>');
    },
    capitalize(s) {
      let first_char = /\S/;
      return s.replace(first_char, function (m) { return m.toUpperCase(); });
    }
  },
}
</script>

<style scoped>
</style>