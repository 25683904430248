<template>
  <div class="loader">
    <span class="stroke"></span>
    <span class="stroke"></span>
    <span class="stroke"></span>
    <span class="stroke"></span>
  </div>
</template>

<script>
export default {
  name: 'SoundWave',
}
</script>

<style scoped>
.loader::v-deep {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 126px;
}
.loader .stroke::v-deep {
  display: block;
  position: relative;
  background: #f8c784;
  height: 100%;
  width: 10px;
  border-radius: 50px;
  margin: 0 5px;
  animation: animate 0.75s linear infinite;
}
@keyframes animate {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}
.stroke:nth-child(1)::v-deep {
  animation-delay: 0.4s;
}
.stroke:nth-child(2)::v-deep {
  animation-delay: 0s;
}
.stroke:nth-child(3)::v-deep {
  animation-delay: 0.6s;
}
.stroke:nth-child(4)::v-deep {
  animation-delay: 0.2s;
}
</style>
